@use 'sass:list';

// responsive sizes
$xs: 320px !default;
$sm: 374px !default;
$md: 640px !default;
$lg: 1024px !default;
$xl: 1200px !default;
$xxl: 1440px !default;
$xxxl: 1600px !default;


// responsive ranges
$xsmall-range: $xs ($sm - 1);
$small-range: $sm ($md - 1);
$medium-range: $md ($lg - 1);
$large-range: $lg ($xl - 1);
$xlarge-range: $xl ($xxl - 1);
$xxlarge-range: $xxl ($xxxl - 1);

@function max-range($range) {
  @return list.nth($range, 2);
}

@function min-range($range) {
  @return list.nth($range, 1);
}

@mixin breakpoint($min, $max: null) {
  @if not $max {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  }
}


@mixin xsmall() {
  @include breakpoint($xsmall-range) {
    @content;
  }
}

@mixin small($qualify: false) {
  @if $qualify == up {
    @include breakpoint($sm) {
      @content;
    }
  } @else if $qualify == down {
    @include breakpoint(0, ($md - 1)) {
      @content;
    }
  } @else {
    @include breakpoint(min-range($small-range), max-range($small-range)) {
      @content;
    }
  }
}


@mixin medium($qualify: false) {
  @if $qualify == up {
    @include breakpoint($md) {
      @content;
    }
  } @else if $qualify == down {
    @include breakpoint(0, max-range($medium-range)) {
      @content;
    }
  } @else {
    @include breakpoint(min-range($medium-range), max-range($medium-range)) {
      @content;
    }
  }
}

@mixin large($qualify: false) {
  @if $qualify == up {
    @include breakpoint($lg) {
      @content;
    }
  } @else if $qualify == down {
    @include breakpoint(0, max-range($large-range)) {
      @content;
    }
  } @else {
    @include breakpoint(min-range($large-range), max-range($large-range)) {
      @content;
    }
  }
}

@mixin xlarge($qualify: false) {
  @if $qualify == up {
    @include breakpoint($xl) {
      @content;
    }
  } @else if $qualify == down {
    @include breakpoint(0, max-range($xlarge-range)) {
      @content;
    }
  } @else {
    @include breakpoint(min-range($xlarge-range), max-range($xlarge-range)) {
      @content;
    }
  }
}

@mixin xxlarge($qualify: false) {
  @if $qualify == up {
    @include breakpoint($xxl) {
      @content;
    }
  } @else if $qualify == down {
    @include breakpoint(0, max-range($xxlarge-range)) {
      @content;
    }
  } @else {
    @include breakpoint(min-range($xxlarge-range), max-range($xxlarge-range)) {
      @content;
    }
  }
}

@mixin xxxlarge() {
  @include breakpoint($xxxl) {
    @content;
  }
}



@mixin approach($media, $from: from-bottom, $range: 20px){
  /*
  USAGE: This function can be used to make small corrections to elements
  just before the view changes from one size to another.

  for example, if you might need to change the font size just before
  the view changes from large to medium

      @include approach(medium, from-top) {
        font-size: 0.8em;
      }


  */

  @if $media == small {
    @if $from == from-top {
      @include breakpoint(max-range($small-range), ($range + max-range($small-range))) {
        @content;
      }
    } @else  {
      @include breakpoint((max-range($xsmall-range) - $range), max-range($xsmall-range)) {
        @content;
      }
    }

  } @else if $media == medium {
    @if $from == from-top {
      @include breakpoint(max-range($medium-range), (max-range($medium-range) + $range)) {
        @content;
      }
    } @else  {
      @include breakpoint((max-range($small-range) - $range), max-range($small-range)) {
        @content;
      }
    }

  } @else if $media == large {
    @if $from == from-top {
      @include breakpoint(max-range($large-range), (max-range($large-range) + $range)) {
        @content;
      }
    } @else  {
      @include breakpoint((max-range($medium-range) - $range), max-range($medium-range) ) {
        @content;
      }
    }

  } @else if $media == xlarge {
    @if $from == from-bottom {
      @include breakpoint((max-range($large-range) - $range), max-range($large-range) ) {
        @content;
      }
    }
  }
}
