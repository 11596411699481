/* Add here all your CSS customizations */
@import "../../apps/porto/static/porto/sass/skin-landing.scss";


::selection {
  background: #ff0000;
}


a:active {
  color: #ff0000 !important;
}

a:focus {
  color: #ff0000 !important;
}

a:hover {
  color: #ff0000 !important;
}


h1 {
  font-size: 6.25rem !important;
  line-height: 100px !important;
  font-weight: 700 !important;
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
  color: #ff0000;
  letter-spacing: 0.1px;

  @include large(down) {
    font-size: 2.5rem !important;
    line-height: 38px !important;
  }
}

h2 {
  margin: 0 0 20px 0;
  font-size: 2.8em !important;
  line-height: 39px !important;
  font-weight: 700 !important;
  color: #ff0000;
  letter-spacing: 0.1px;
  text-transform: none;
  @include large(down) {
    text-align: left !important;
    font-size: 2.3em !important;
    line-height: 30px !important;
  }
}


h3 {
  font-size: 2.8em;
  font-weight: 200;
  line-height: 45px;
  margin-bottom: 33px;
  letter-spacing: 0.1px;
  text-transform: none;
  @include large(down) {
    font-size: 2em;
    line-height: 24px;
    margin-bottom: 22px;
    text-align: left !important;

  }
}

p {
  font-weight: 400 !important;
  line-height: 1.7 !important;
  font-size: 1.50em !important;
  color: black;
  margin: 0;
  text-transform: none;
  @include large(down) {
    text-align: left !important;
    line-height: 1.5 !important;
    font-size: 1.30em !important;
  }
}


a {
  color: black;
}

section.white-section.section {
  background-color: white;
}

.container {
  max-width: 1140px !important;

  &.narrow {
    max-width: 900px !important;
  }
}


#header .header-nav.header-nav-links nav > ul:not(:hover) > li > a.active {
  color: #ff0000 !important;
}


#header .header-nav.header-nav-links.header-nav-light-text nav > ul > li > a, #header .header-nav.header-nav-line.header-nav-light-text nav > ul > li > a {
  color: black;
}

#header .header-btn-collapse-nav {
  background: white;
  color: black;
}

#header .header-nav.header-nav-links.header-nav-light-text nav > ul > li > a:hover {
  color: #ff0000 !important;
}


.header-nav-main.header-nav-main-mobile-dark:before {
  @include large(down) {
    background-color: white !important;
  }
}

#header .header-nav-main.header-nav-main-mobile-dark nav > ul > li > a.active {
  @include large(down) {
    background: none !important;
    color: #ff0000 !important;
  }
}


#header .header-nav.header-nav-links.header-nav-light-text nav > ul > li > a, #header .header-nav.header-nav-line.header-nav-light-text nav > ul > li > a {
  @include large(down) {
    color: black !important;
  }
}

@media (max-width: 992px) {
  #header .header-nav.header-nav-links nav > ul:not(:hover) > li > a.active {
    color: white !important;
  }

}

@media (max-width: 992px) {
  #header .header-nav-main.header-nav-main-mobile-dark nav > ul li a:hover, #header .header-nav-main.header-nav-main-mobile-dark nav > ul li a:focus, #header .header-nav-main.header-nav-main-mobile-dark nav > ul li a:active {
    background: white !important;
  }
}


#header {
  .header-nav-main {
    nav {
      & > ul {

        // not hovering on ul
        &:not(:hover) {
          li {
            a {
              color: black;

              &.active {
                background-color: unset;
                color: #ff0000
              }
            }
          }
        }

        // hovering on ul
        &:hover {
          li {
            a {
              background-color: unset;
              color: black;

              &.active {
                // when mouse is over ul, un-highlight the active item
                color: unset;
              }

              &:hover {
                color: #ff0000
              }
            }
          }
        }
      }
    }
  }
}

#header .header-nav-main nav > ul > li.dropdown.open > a::before, #header .header-nav-main nav > ul > li.dropdown:hover > a::before {
  border-bottom-color: transparent;

}

#header .header-nav-main nav > ul > li.dropdown .dropdown-menu li a:hover, #header .header-nav-main nav > ul > li.dropdown .dropdown-menu li a:focus, #header .header-nav-main nav > ul > li.dropdown .dropdown-menu li a.active, #header .header-nav-main nav > ul > li.dropdown .dropdown-menu li a:active {
  color: #ff0000
}


#header .header-nav-main nav > ul li {
  border-bottom: 0 !important;

}

#header {
  .header-container {
    &.container {
      max-width: 1300px !important;
    }

  }
}

@media (max-width: 1255px) {
  #header .header-nav-main.header-nav-main-font-lg-upper-2 nav > ul > li > a {
    font-size: 0.72rem !important;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0;
    padding: 0.4rem !important;
  }
}


ul.nav {
  li.dropdown {
    a.dropdown-item {
      padding-bottom: 0 !important;
      @include large(down) {
        padding-bottom: 7px !important;
      }
    }
  }
}

ul.nav > li:first-child {
  display: none;
  @include large(down) {
    display: block;
  }
}


.sub-menu-active {
  color: green;
}

ul.dropdown-menu {
  background-color: white !important;
  top: 40px !important;

  li {
    a.dropdown-item {
      border-bottom: 0 !important;
      padding: 16px 18px 5px 18px !important;
      font-size: 0.9rem !important;
      text-transform: uppercase !important;
      letter-spacing: 0 !important;

      &:nth-of-type(1) {
        padding-top: 0 !important;
      }
    }

    &:hover {
      a {
        background-color: transparent !important;
        color: #ff0000 !important;
      }
    }
  }
}

#header .header-nav-main.header-nav-main-mobile-dark nav > ul > li > a.active:focus, #header .header-nav-main.header-nav-main-mobile-dark nav > ul > li > a.active:hover {
  @include large(down) {
    background: none !important;
  }
}

.parallax:not(.page-header):not(.match-height):not([class*="section-height-"]) {
  margin: 0;
}

.parallax-background {
  background-position-x: 50%;
  height: 100% !important;
  @include large(down) {
    height: 110% !important;
  }
}


.thumb-info .thumb-info-title {
  @include large(down) {
    font-size: 1rem;
  }
}

.alternative-font-5 {
  font-family: poppins, Arial, sans-serif !important;
}


.sticky-header-active {
  .sundayramp {
    .nav-link {
      color: black !important;

      &:hover {
        color: #ff0000 !important;
      }
    }

    .header-logo {
      img {
        filter: invert(0);
      }
    }
  }
}


body.sundayramp {
  .breadcrumb {
    display: none;
  }

  .nav-link {
    color: white !important;

    &:hover {
      color: #ff0000 !important;
    }
  }


  .header-logo {
    img {
      filter: invert(1);
    }
  }
}

@media (max-width: 992px) {
  body.sundayramp {


    .nav-link {
      color: black !important;


    }

  }
}


section {
  &.section {
    border-top: 0;
    margin: 0;
    padding: 80px 0 80px 0;
    @include large(down) {
      padding: 20px 5px 20px 5px;
    }
  }

  &.breadcrumb {
    display: none;
    padding-top: 9rem;

    ul {
      li {
        a {
          &:hover {
            color: #ff0000 !important;
          }
        }
      }
    }

    .separator {
      display: none;
    }

  }

  &.theme-solar {
    background-color: white;
  }

  &.theme-dark {
    background-color: #1c2023;

    h2 {
      color: white;
    }

    h5 {
      color: white;
    }

    p {
      color: white;
    }
  }

  &.theme-grey {
    background-color: #EDEDED;
  }

  &.text-image {
    p {
      margin-bottom: 40px;
    }
  }

  &.image-frames {
    p {
      margin-bottom: 30px;

      &.text {
        margin-bottom: 0;
      }
    }

    .thumb-info {
      margin-bottom: 1.5rem;
    }
  }

  &.video-text {
    .text-video {
      margin-top: 30px;
    }
  }

  &.bild-text {
    img {
      margin-bottom: 30px;
    }
  }

  &.dividing-line {
    padding: 0;
    @include large(down) {
      padding: 30px 0 30px;
    }

    .container-fluid {
      padding-left: 0;
      padding-right: 0;
    }

    hr {
      margin: 0;

      &.solid {
        background: lightgrey;
        height: 2px;
      }
    }
  }

  &#team {
    &.image-frames {
      .thumb-info {
        margin-bottom: 0.5rem;
      }
    }
  }

  &#kontakt {
    .titel-text {
      h2 {
        @include large(down) {
          text-align: center !important;
        }
      }

      p {
        margin-bottom: 40px;
        @include large(down) {
          text-align: center !important;
        }
      }
    }

    .icon-box {
      .col {
        display: flex;

        &:first-of-type {
          justify-content: flex-end;
          @include large(down) {
            justify-content: center !important;
            margin-bottom: 2rem;
          }
        }

        &:nth-of-type(2) {
          justify-content: center;
        }

        &:last-of-type {
          justify-content: flex-start;
          @include large(down) {
            justify-content: center !important;
            margin-bottom: 2rem;
          }
        }

        .feature-box-icon {
          i {
            position: relative;
            right: 17px;
            top: 1px;
            font-size: 26px;
          }

          @include large(down) {
            display: none;
          }
        }

        .feature-box-info {
          @include large(down) {
            p {
              text-align: center !important;

            }
          }

          p, a {
            color: white;
          }

          h5 {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  &.parallax {
    margin: 0 !important;
    padding: 100px 0 120px 0 !important;
    @include large(down) {
      padding: 50px 0 60px 0 !important;
    }

    .btn {
      margin-top: 30px;
    }
  }

  &.image-fullwidth {
    padding: 0;

    .container-fluid {
      padding-left: 0;
      padding-right: 0;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  &.image-fullwidth-text {
    padding-top: 0;
    padding-bottom: 120px;
    @include large(down) {
      padding-bottom: 40px;
    }


    .container-fluid {
      padding-left: 0;
      padding-right: 0;

      img {
        width: 100%;
        height: auto;
      }
    }

    .container {
      &:last-of-type {
        margin-top: 30px;
      }
    }
  }
}


#home {
  height: 100vh;

  &.background-image {
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;
    @include large(down) {
      background-position: center;
    }
  }

  &.parallax {
    padding: 50px !important;
    @include large(down) {
      padding: 15px !important;
    }

  }

  @include large(down) {
    height: 40vh;
  }

  .main-hero-title {
    margin-top: 10rem;
    @include large(down) {
      margin-top: 6rem;
    }
  }

}

@media (max-width: 380px) {
  #home {
    height: 47vh;
  }
}


@media (max-width: 322px) {
  #home {
    height: 52vh;

    h1 {
      font-size: 3rem !important;
      line-height: 60px !important;
    }
  }
}

#lead-text {
  h2 {
    font-size: 5em !important;
    line-height: 65px !important;
    font-weight: 700 !important;
    color: #ff0000;
    margin-bottom: 48px;
    letter-spacing: 0.1px;
    @include large(down) {
      font-size: 3.6em !important;
      line-height: 50px !important;
      text-align: left !important;
    }
    @include small(down) {
      font-size: 2.5em !important;
      line-height: 38px !important;
    }
  }

  h3 {
    font-size: 2.8em;
    font-weight: 200;
    line-height: 45px;
    margin-bottom: 33px;
    letter-spacing: 0.1px;
    @include large(down) {
      font-size: 1.5em;
      line-height: 24px;
      text-align: left !important;
      margin-bottom: 0 !important;
    }
  }

  p {
    font-weight: 400 !important;
    line-height: 1.7 !important;
    font-size: 1.50em !important;
  }
}


.rampe-description {
  h3 {
    @include large(down) {
      margin-bottom: 20px;
      font-size: 2.2em;
      font-weight: 200;
      line-height: 31px;
      text-align: left !important;
    }
  }
}

#team {
  &.image-frames {
    .thumb-info-title {
      background: none;
    }
  }
}


.content-image {
  height: 100vh;
  @include large(down) {
    height: 30vh;
  }
}

.video-text {
  .col {
    h2 {
      margin-top: 30px;
    }
  }
}


.list-h3 {
  h3 {
    margin-bottom: 10px;
  }
}

.btn-cstm {
  background: #ff0000;
  color: white;
  border: 1px solid #ff0000;

  &:hover {
    text-decoration: none !important;
    color: #ff0000;
    background: white;
    border: 1px solid #ff0000;
  }
}

.video-text {
  .position-relative {
    @include large(down) {
      padding-left: 0;
      padding-right: 0;
    }
    @include large(down) {
      .ratio-4x3 {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}


footer {
  background-color: white !important;

  p {
    font-size: 1.5rem !important;
  }
}


.social-icons li a {
  font-size: 1.8rem;
  height: 68px;
  line-height: 68px;
  width: 68px;
}


.thumb-info .thumb-info-title {
  color: black;
  font-size: 24px;
  display: flex;
  justify-content: center;
}

.thumb-info-inner {
  background: white;
  padding: 10px;
  width: auto;
}

.copyright {
  border-top: 1px solid white !important;
  padding: 15px !important;

  p {
    font-weight: 400 !important;
    font-size: 0.9em !important;
    color: white !important;
    @include large(down) {
      text-align: center !important;
    }
  }

  a {
    font-weight: 400 !important;
    font-size: 0.9em !important;
    color: white !important;
  }
}


.owl-prev {
  &.disabled {
    background-color: #ff0000 !important;
    border-color: #ff0000 !important;
  }
}

.owl-carousel:not(.nav-arrows-1):not(.show-nav-title) .owl-nav button[class*="owl-"]:active, .owl-carousel:not(.nav-arrows-1):not(.show-nav-title) .owl-nav button[class*="owl-"].active {
  background-color: #ff0000 !important;
  border-color: #ff0000 !important;
}

.owl-carousel:not(.nav-arrows-1):not(.show-nav-title) .owl-nav button[class*="owl-"]:active, .owl-carousel:not(.nav-arrows-1):not(.show-nav-title) .owl-nav button[class*="owl-"] {
  background-color: #ff0000 !important;
  border-color: #ff0000 !important;
}

#lead-text-subpages {
  padding-top: 7rem;

  h1 {
    font-size: 5em !important;
    line-height: 65px !important;
    font-weight: 700 !important;
    color: #ff0000;
    margin-bottom: 48px;
    letter-spacing: 0.1px;
    @include large(down) {
      font-size: 3.5em !important;
      line-height: 40px !important;
    }
  }
}

.page-header-normal {
  margin-bottom: 0;

  h1 {
    font-size: 2.1rem !important;
    line-height: 2.5rem !important;
  }
}


form {
  label {
    color: black;
    font-weight: bold;
  }

  table {
    tr {
      border-bottom: 0;
      display: flex;
      flex-direction: column;

      th, td {
        padding: 5px 10px;
      }

    }
  }

  input, textarea {
    display: block;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-top-color: rgb(206, 212, 218);
    border-right-color: rgb(206, 212, 218);
    border-bottom-color: rgb(206, 212, 218);
    border-left-color: rgb(206, 212, 218);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    @include large(down) {
      width: 100%;
    }
  }

  .btn-cstm {
    margin-top: 1rem;
    margin-left: 0.5rem;
  }
}

.nk-cookie-banner {
  background-color: #1c2023;
  color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  border-radius: 0;
  display: none;

  a {
    color: white;
  }
}


.referenzen {
  .owl-nav {
    @include large(down) {
      display: none;
    }
  }

  .owl-carousel .owl-nav button.owl-next::before {
    font-weight: 800;
    font-size: 1.5rem;
    left: 1px;
    top: 3px;
  }

  .owl-carousel .owl-nav button.owl-next {
    width: 50px;
    height: 50px;
  }

  .owl-carousel .owl-nav button.owl-prev {
    width: 50px;
    height: 50px;
  }

  .owl-carousel .owl-nav button.owl-prev::before {
    font-weight: 800;
    font-size: 1.5rem;
    left: 1px;
    top: 3px;
  }
}

.owl-carousel.dots-inside .owl-dots {
  bottom: -20px;
  width: 100%;
  @include large(up) {
    display: none;
  }
}

.owl-theme .owl-dots .owl-dot span {
  &:hover {
    background: white;

    span {
      background: white;
    }
  }
}

.owl-carousel .owl-dots .owl-dot.active span, .owl-carousel .owl-dots .owl-dot:hover span {
  background-color: #ff0000;
}

.single-post {
  margin-top: 8rem;
}

article.post .post-date .month {
  color: white !important;
  background: #ff0000 !important;
}

article.post .post-date .day {
  color: #ff0000 !important;
}

.post-content {
  h2 {
    font-size: 1.8rem !important;
  }
}

.kontakt {
  .custom-contact {
    p {
      text-align: center !important;
    }
  }
}

.social {
  margin-top: 2rem;
  margin-bottom: 4rem;
}

table {
  tr {
    border-top: none;
    border-bottom: 1px solid #ff0000;

    td {
      border: none;

      mainNav
      &.pinky {
        background-color: #FFF3F3;
      }
    }
  }
}

img.kurse-logo {
  width: 30% !important;
  @include large(down) {
    width: 50% !important;
  }
}


figcaption {
  @include large(down) {
    line-height: 16px !important;
    width: 100% !important;
    margin-top: 3px !important;
    text-align: left !important;
  }
}

nav.pagination {
  .current {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
}

.pagination > a {
  color: black;
}

.card {
  border: 3px solid rgb(0, 0, 0);
  padding: 0.5rem;
  border-radius: 1.25rem;
  text-align: center;
  margin-bottom: 3rem;

  .card-header {
    padding: 0.5rem;
    background-color: white;
    border: none;

    p {
      text-transform: uppercase;
      font-size: 1.5rem;
      line-height: 28px;
      color: #ff0000;
      @include medium(down) {
        text-align: center !important;
      }
    }
  }

  .card-body {
    padding: 0 0.5rem 0.5rem 0.5rem;
    text-align: center;

    p {
      text-transform: uppercase;
      font-size: 1.5rem !important;
      line-height: 28px !important;
      text-align: center !important;

      &:last-of-type {
        padding-top: 0.5rem;
        padding-bottom: 1rem;
      }
    }

    a {
      text-decoration: underline;
      font-size: 1rem;
      font-weight: 400;
      line-height: 28px;
      color: #ff0000;
      text-align: center;
    }

  }

}

.au-gallery {
  .col {
    margin-bottom: 1rem;

  }
}

.contact-form-offerte {
  .container {
    border: 3px solid red;
    border-radius: 20px;
    max-width: 900px !important;
  }
}


au-gallery {
  .row {
    margin-top: 1.5rem;
  }

  .col {
    margin-bottom: 1.5rem;
  }
}

ul.nav > li:first-child {
  display: block;
}


blockquote {
  width: 80%;
  margin: 3% auto;
  border-left: 0;
  text-align: center; /* Keeps the text centered */
  position: relative; /* Ensure relative positioning for any additional adjustments */
  color: #333;
  margin-bottom: 0;
  @include medium(down) {
    width: 90% !important; /* Make the blockquote narrower for mobile */
    margin: 20px auto !important; /* Adjust margin to center properly */
    text-align: left !important; /* Align the text to the left for better readability */
  }

  .quote-mark {
    font-family: Georgia, sans-serif;
    font-size: 36px; /* Adjust this size to fit your design */
    font-style: normal;
    color: #ff0000;
    vertical-align: middle; /* Ensures the quotes are aligned with the text */
    line-height: 0.8; /* Adjust the height within the inline context */
    transform: scaleY(0.8); /* Scale the quotes vertically */
    margin-right: 5px; /* Bring the opening quote closer to the text */
    margin-left: 5px; /* Bring the closing quote closer to the text */
    font-weight: bold;
    @include medium(down) {
      font-size: 30px !important;
      margin-right: 0 !important;
      margin-left: 5px !important;
    }

  }

  p {
    line-height: 1.7 !important;
    color: #333;
    letter-spacing: 0.5px; /* Slightly wider spacing */
    @include medium(down) {
      font-size: 1rem !important;
      line-height: 1.6 !important;
    }
  }

}


p.avatar-image {
  width: 100%;
  text-align: center !important;
  margin-bottom: 0 !important;

}

.profile-name {
  font-weight: 800;
  font-size: 1.4rem !important;
  text-align: center !important;
  margin-top: 10px !important;
  text-transform: uppercase;
  margin-bottom: 0 !important;
  @include medium(down) {
    font-size: 1.2rem !important;
  }
}

.profile-title {
  font-weight: 400 !important;
  font-size: 0.9rem !important;
  text-align: center !important;
  color: #555 !important;
  margin-bottom: 0 !important;
  @include medium(down) {
    font-size: 0.85rem !important;
  }
}

.rounded {
  width: 150px !important;
  height: 150px !important;
  border-radius: 50% !important;
  object-fit: cover !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  @include medium(down) {
    width: 100px !important;
    height: 100px !important;
  }
}

.video-container {
  position: relative;
  max-width: 400px; /* Maximale Breite für Desktop */
  margin: 0 auto;
  overflow: hidden;
}

video {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* Passt die Höhe an die Breite an, um das Seitenverhältnis zu wahren */
  max-height: 700px; /* Begrenze die maximale Höhe auf 700px */
  object-fit: cover; /* Stellt sicher, dass das Video vollständig angezeigt wird, ohne Verzerrung */
  z-index: 1;

}

@media (max-width: 768px) {
  .video-container {
    max-width: 100%;
    //max-height: 500px; /* Begrenze die Höhe für mobile Geräte */
    padding: 2.5rem 0.8rem 0;
  }
  video {
    max-height: unset;
  }

  .section {
    &.video {
      padding-bottom: 0;
    }
  }
}
