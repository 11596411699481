

@import 'mixins/responsive';
@import "custom";
.main {
  img {
    width: 100%;
    height: auto;
  }
}

section.section {
  @include large(down) {
  }
  // from-bottom ist default, ist hier optional gezeigt, muss gar nicht angegeben werden
  @include approach(large, from-bottom, 22px) {
  /*  background-color: orange;*/
  }
  // from-top muss angegeben werden, weil sonst wird fron-bottom verstanden
  @include approach(medium, from-top, 22px) {
   /* background-color: aquamarine;*/
  }
}


.grecaptcha-badge {
  bottom: 64px!important;
}
